var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DataTable',{attrs:{"type":"expenses","featuresMap":{
		add: _vm.features.addExpense,
		edit: _vm.features.editExpense,
		delete: _vm.features.deleteExpense,
		addFiles: true,
		listTotals: true,
	},"title":"Despesas","filtersConfig":_vm.filtersConfig,"getItems":_vm.getExpenses,"patchItem":_vm.patchExpense,"deleteItem":_vm.deleteExpense,"actions":['comments', 'files', 'changes', 'edit', 'delete'],"profileOverwrite":_vm.profileOverwrite},scopedSlots:_vm._u([{key:"totals",fn:function({ monthly, totals }){return [_c('ExpenseTotals',{attrs:{"id":"totals","monthly":monthly,"totals":totals}})]}},{key:"detail",fn:function({ selectedItem, closeDetail }){return [_c('ExpenseForm',{attrs:{"expense":selectedItem,"onClose":closeDetail}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }