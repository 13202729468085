<template>
	<DataTable
		type="expenses"
		:featuresMap="{
			add: features.addExpense,
			edit: features.editExpense,
			delete: features.deleteExpense,
			addFiles: true,
			listTotals: true,
		}"
		title="Despesas"
		:filtersConfig="filtersConfig"
		:getItems="getExpenses"
		:patchItem="patchExpense"
		:deleteItem="deleteExpense"
		:actions="['comments', 'files', 'changes', 'edit', 'delete']"
		:profileOverwrite="profileOverwrite"
	>
		<template v-slot:totals="{ monthly, totals }">
			<ExpenseTotals id="totals" :monthly="monthly" :totals="totals" />
		</template>
		<template v-slot:detail="{ selectedItem, closeDetail }">
			<ExpenseForm :expense="selectedItem" :onClose="closeDetail" />
		</template>
	</DataTable>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import DataTable from "../components/DataTable.vue";
import ExpenseForm from "../components/ExpenseForm.vue";
import ExpenseTotals from "../components/ExpenseTotals.vue";

import { getExpenses, patchExpense, deleteExpense } from "../api/expenses";

export default Vue.extend({
	name: "Expenses",
	props: ["profileOverwrite"],
	components: { DataTable, ExpenseForm, ExpenseTotals },
	computed: mapState(["features"]),
	data() {
		return {
			filtersConfig: [
				{
					type: "autocomplete",
					label: "Categorias",
					value: "category",
					items: "config.expenseCategories",
					itemValue: "key",
				},
				{
					type: "autocomplete",
					label: "Sub-Categorias",
					value: "subCategory",
					items: "config.expenseSubCategories",
					itemValue: "key",
				},
				{
					type: "buttonToggle",
					value: "repeatsMonthly",
					options: [
						{ value: false, label: "Não Repete" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Repete" },
					],
				},
			],
		};
	},
	methods: { getExpenses, patchExpense, deleteExpense },
});
</script>
