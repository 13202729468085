<style>
.card {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	padding: 10px;
}

.totalBlock {
	border: 1px solid #777;
	border-radius: 3px;
	padding: 10px;
	width: 215px;
}

.totalValues {
	text-align: left;
	margin-top: 10px;
}

.totalWithPercent {
	display: flex;
	gap: 10px;
	justify-content: space-between;
	margin-top: 10px;
}
</style>

<template>
	<v-skeleton-loader
		v-if="!totals"
		type="image"
		class="mx-auto"
		style="width: 100%; height: 125px; max-width: 1175px"
	/>
	<v-card v-else class="card mx-auto">
		<div class="totalBlock">
			Margem
			<div style="font-size: 0.75em; color: DarkGrey">(Mês)</div>
			<div class="totalValues" style="text-align: center">
				<PercentageChip
					:stats="monthly"
					stat="margin"
					:absoluteFirst="true"
					:currency="true"
					:onClick="() => {}"
				/>
				<br />
				<PercentageChip :stats="monthly" stat="margin" :onClick="() => {}" />
			</div>
		</div>
		<div class="totalBlock">
			Receita
			<div style="font-size: 0.75em; color: DarkGrey">(Mês)</div>
			<div class="totalValues">
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ formatNumber(monthly.billing, true) || "0 €" }}
						</span>
					</template>
					<span>Faturação</span>
				</v-tooltip>
				<div class="totalWithPercent">
					<v-tooltip right>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								{{ formatNumber(monthly.billingBanks, true) || "0 €" }}
							</span>
						</template>
						<span>Faturação (Diversos)</span>
					</v-tooltip>
					<v-chip small>
						{{ formatNumber((monthly.billingBanks * 100) / monthly.billing) || 0
						}}<v-icon x-small right>mdi-percent</v-icon>
					</v-chip>
				</div>
			</div>
		</div>
		<div class="totalBlock">
			Despesas
			<div style="font-size: 0.75em; color: DarkGrey">(Mês)</div>
			<div class="totalValues">
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ formatNumber(monthly.salesCost, true) || "0 €" }}
						</span>
					</template>
					<span>Custo de Venda</span>
				</v-tooltip>
			</div>
			<div class="totalValues">
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ formatNumber(monthly.fixedCost, true) || "0 €" }}
						</span>
					</template>
					<span>Custo Fixo</span>
				</v-tooltip>
			</div>
		</div>
		<div class="totalBlock">
			Margem
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues" style="text-align: center">
				<PercentageChip :stats="totals" stat="margin" :absoluteFirst="true" :currency="true" :onClick="() => {}" />
				<br />
				<PercentageChip :stats="totals" stat="margin" :onClick="() => {}" />
			</div>
		</div>
		<div class="totalBlock">
			Receita
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ formatNumber(totals.billing, true) || "0 €" }}
						</span>
					</template>
					<span>Faturação</span>
				</v-tooltip>
				<div class="totalWithPercent">
					<v-tooltip right>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								{{ formatNumber(totals.billingBanks, true) || "0 €" }}
							</span>
						</template>
						<span>Faturação (Diversos)</span>
					</v-tooltip>
					<v-chip small>
						{{ formatNumber((totals.billingBanks * 100) / totals.billing) || 0
						}}<v-icon x-small right>mdi-percent</v-icon>
					</v-chip>
				</div>
			</div>
		</div>
		<div class="totalBlock">
			Despesas
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">{{ formatNumber(totals.salesCost, true) || "0 €" }}</span>
					</template>
					<span>Custo de Venda</span>
				</v-tooltip>
			</div>
			<div class="totalValues">
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">{{ formatNumber(totals.fixedCost, true) || "0 €" }}</span>
					</template>
					<span>Custo Fixo</span>
				</v-tooltip>
			</div>
		</div>
	</v-card>
</template>

<script>
import Vue from "vue";

import PercentageChip from "../components/dashboard/PercentageChip.vue";

import { formatNumber } from "../utils/utils";

export default Vue.extend({
	name: "ExpenseTotals",
	props: ["monthly", "totals"],
	components: { PercentageChip },
	methods: {
		formatNumber,
	},
});
</script>
